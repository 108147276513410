import { useState } from "react";
import useEvent from "./useEvent";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Game.css";

const questions = [
  "Ich habe noch nie1",
  "Ich habe noch nie2",
  "Ich habe noch nie3",
  "Ich habe noch nie4",
  "Ich habe noch nie5",
  "Ich habe noch nie6",
  "Ich habe noch nie7",
  "Ich habe noch nie 8",
];

const ichhabenochnie = [
  "Wer würde am ehesten eine Beschwerde wegen zu lautem Sex vom Nachbarn bekommen?",
  "Ich habe noch nie in die Dusche gepinkelt",
  "Wer hat de längste Sex Durststrecke? Der oder die Ungefickte trinkt 3 Schlücke",
  "Wer würde am ehesten ungeladen auf der Hochzeit eines Ex-Partners erscheinen? Die Person trinkt 2 Schlücke.",
  "Wer würde am ehesten Sperma ins Auge bekommen? Genius trinkt 3 Schlücke.",
  "Wer würde am ehesten ein Arschloch lecken? Der/ Die Genießer*in trinkt 4 Schlücke.",
  "Trinke 3 Schlücke, wenn du schon mal Analsex hattest.",
  "Verteile 2 Schlücke, wenn du schon ein Kleidungsstück ausziehen musstest.",
  "Alle, die ein dunkles Oberteil tragen, müssen ein Kleidungsstück ausziehen (Socken zählen nicht)",
  "Trinke 2 Schlücke, wenn du Nagellack oder Klarlack trägst",
  "Trinke 2 Schlücke, wenn du keinen Nagellack trägst",
  "Verteile 3 Schlücke, wenn du Lippenstift trägst",
  "Verteile 2 Schlücke, wenn du etwas Rotes trägst (Unterwäsche zählt).",
  "Trinke 3 Schlücke, wenn du seit 10 Minuten nicht trinken musstest.",
  "Lieber Ketchup oder Mayo. Stimmt alle gleichzeitig ab und die Verlierer trinken 3 Schlücke",
  "Lieber Doggy oder Missionarsstellung. Stimmt alle gleichzeitig ab und die Verlierer trinken 5 Schlücke.",
  "Was war zuerst da: Das Ei oder das Huhn. Stimmt gleichzeitig ab und die Verlierer trinken 4 Schlücke. Bei Gleichstand trinken alle 2.",
  "Wer endet beim Sex am ehesten in Handschellen? Die/Der Unterwürfige trinkt 2 Schlucke.",
  "Verteile 2 Schlücke, wenn du keine schwarzen Socken trägst.",
  "Wer würde am ehesten eine Golden shower genießen. Die Fetischqueen ext sein/ihr Glas.",
  "Wer würde am ehesten bei der Arbeit masturbieren. Der/Die Mutige verteilt 5 Schlücke.",
  "Esse einen Teelöffel Zimt oder exe ein Glas deiner Mitspieler",
  "Trinke 3 Schlücke, wenn du schon mehr als 10 Geschlechtspartner hattest",
  "Wer würde eher für Sex Geld verlangen. Die Hure darf 5 Schlücke verteilen.",
  "Themenspiel und 2 Schlücke für den Verlierer: Star Wars Charaktere.",
  "Wer würde sich eher untenrum piercen lassen? Der/Die Mutige trinkt 2 Schlücke",
  "Wer wird heute am meisten trinken. Der/ Die Suffmeister*in darf 5 Schlücke verteilen.",
  "Wer würde am ehesten eine Zombie Apokalypse überleben. Das Genie trinkt 2 Schlücke.",
  "Wer hat mit 60 den höchsten Body Count? Sexbomb trinkt 3 Schlücke.",
  "Themenspiel und 4 Schlücke für den Verlierer: Kleidungsstücke.",
  "Themenspiel und 3 Schlücke für den Verlierer: Cocktails.",
  "Wer würde eher einen Kaffeefilter als Kondom benutzen. Die doofe Sau trinkt 5 Schlücke.",
  "Wer würde eher mit einem Freund/ einer Freundin gemeinsam Pornos schauen. Die Person trinkt 2 Schlücke.",
  "Wer würde am ehesten in den Puff gehen. Die Person trinkt 2 Schlücke.",
  "Themenspiel und 3 Schlücke für den Verlierer: Automarken.",
  "Wer aus der Runde keine blaue Jeans anhat, muss zwei Shots direkt hintereinander auf ex trinken.",
  "Alle, die die Zunge rollen können, sind safe. Alle anderen müssen einen Schluck trinken.",
  "Jeder, der eine Silvia persönlich kennt, muss fünf Schlücke trinken.",
  "Ich habe noch nie beim Anblick eines Penis lachen müssen",
  "Ich habe noch nie gelogen.",
  "Ich habe noch nie betrogen.",
  "Ich habe noch nie gestohlen.",
  "Ich habe noch nie Drogen ausprobiert.",
  "Ich habe noch nie Handschellen getragen.",
  "Ich habe mich noch nie in ein Familienmitglied verliebt.",
  "Ich habe mich noch nie geprügelt.",
  "Ich habe noch nie jemanden wissentlich um Geld betrogen.",
  "Ich habe noch nie bei der Beichte gelogen.",
  "Ich habe noch nie Vandalismus betrieben.",
  "Ich habe mir noch nie gewünscht, mit jemanden den Körper tauschen zu können.",
  "Ich habe noch nie meine Eltern beklaut.",
  "Ich habe noch nie jemanden gemobbt.",
  "Ich habe noch nie die Zeche geprellt.",
  "Ich habe noch nie über jemanden Lügen verbreitet.",
  "Ich habe noch nie gekifft.",
  "Ich habe noch nie mit jemanden so gesprochen, dass er / sie geweint hat.",
  "Ich habe mir noch nie vor Angst in die Hose gemacht.",
  "Ich konnte noch nie Sex und Liebe trennen.",
  "Ich habe noch nie ein Fahrrad geklaut.",
  "Ich habe noch nie einen Gegenstand absichtlich kaputt gemacht, weil ich neidisch war.",
  "Ich habe noch nie im Zorn Gegenstände nach jemanden geworfen.",
  "Ich habe noch nie die Toilette des anderen Geschlechts benutzt.",
  "Ich habe noch nie jemanden gestalkt.",
  "Ich habe heute noch nie gefurzt.",
  "Ich war noch nie länger als 1 Jahr in eine Person verliebt, ohne mit ihr etwas zu haben.",
  "Ich war noch nie in eine Affäre verliebt.",
  "Ich habe noch nie einen Anwalt benötigt.",
  "Ich habe noch nie persönlich einen Drogendealer aufgesucht.",
  "Ich wollte noch nie eine platonische Freundschaft in eine „Freundschaft Plus“ verwandeln.",
  "Ich bin noch nie betrunken Auto gefahren.",
  "Ich habe noch nie so üblen Sex gehabt, dass ich danach Schluss gemacht habe.",
  "Ich habe noch nie gedacht, dass einer der Mitspieler einem Charakter aus einer Serie ähnelt.",
  "Ich wollte noch nie meine Familie bei Mitten im Leben anmelden.",
  "Ich habe noch nie ehrenamtlich für die Gesellschaft gearbeitet.",
  "Ich habe noch nie ein Geheimnis gehabt, das niemand außer mir jemals wissen darf.",
  "Ich habe noch nie gedacht, dass einer der Mitspieler heißer & attraktiver ist als mein momentaner Partner.",
  "Ich habe noch nie die Polizei geschmiert / bestochen.",
  "Ich habe noch nie einen Mitspieler gegoogelt.",
  "Ich habe mich noch nie absichtlich selbst verletzt.",
  "Ich bin noch nie bei einem Date ohne Unterwäsche aufgetaucht.",
  "Ich habe noch nie daran gedacht, jemanden richtig weh zu tun.",
  "Ich habe noch nie die Liebe meines Lebens getroffen.",
  "Ich habe noch nie Blut gespendet.",
  "Ich habe noch nie einen AIDS-Test gemacht.",
  "Ich habe noch nie Kummer in Alkohol ertränkt, bis ich nicht mehr gerade laufen konnte.",
  "Ich habe noch nie jemanden ins Auto gekotzt.",
  "Ich hatte noch nie eine Frisur, für die ich mich heute abgrundtief schäme.",
  "Ich habe noch nie länger als 3 Stunden mit einer Person telefoniert.",
  "Ich habe noch nie jemanden aus tiefsten Herzen gehasst.",
  "Ich hab noch nie im Auto Sex gehabt.",
  "Ich habe noch nie Penisbilder gegoogelt.",
  "Ich habe noch nie Angst vor Sex gehabt.",
  "Ich wurde noch nie von Eltern beim Sex erwischt.",
  "Ich habe noch nie mit einem Mitspieler hier Sex gehabt.",
  "Ich habe noch nie davon geträumt, meinem Partner beim Sex mit jemand anderem zuzuschauen.",
  "Ich hatte noch nie Schmerzen bei oder nach dem Sex.",
  "Ich habe noch nie den Bruder oder die Schwester meines Partners begehrt.",
  "Ich war noch nie schwanger / habe noch nie jemanden geschwängert.",
  "Ich habe noch nie einen Dreier gehabt.",
  "Ich habe noch nie auf eine/n platonische/n Freund/in masturbiert.",
  "Ich habe noch nie mit dem gleichen Geschlecht experimentiert.",
  "Ich habe noch nie einen One Night Stand gehabt.",
  "Ich habe mich noch nie mithilfe von Lebensmitteln selbst befriedigt.",
  "Ich habe noch nie Sex im Freien gehabt.",
  "Ich habe noch nie als heimlicher Voyeur jemanden beim Sex / beim Ausziehen / unter der Dusche beobachtet.",
  "Ich habe noch nie jemanden zu mir nachhause eingeladen, nur um Sex zu haben.",
  "Ich habe noch nie jemanden nach dem Sex oral zum Orgasmus gebracht.",
  "Ich habe noch nie mit jemand der vergeben war Sex gehabt.",
  "Ich habe noch mit jemanden, den ich im Internet kennen gelernt habe Sex gehabt.",
  "Ich habe mich noch nie nach dem Sex geschämt.",
  "Ich habe noch nie einen multiplen Orgasmus gehabt.",
  "Ich habe noch nie Sexspielzeug benutzt.",
  "Ich habe noch nie ein Date von jemanden von Tinder / Grindr etc. gehabt.",
  "Ich habe noch nie mit einem Date von Tinder / Grindr etc. geschlafen.",
  "Ich habe noch nie beim Anblick eines Penis gelacht.",
  "Ich habe noch nie mit meinem Partner einen Swinger Club besucht.",
  "Ich habe noch nie mehr als einen anderen Menschen am gleichen Tag befriedigt.",
  "Ich habe noch nie einen Pornofilm angeschaut.",
  "Ich habe noch nie auf eine MILF gestanden.",
  "Ich dachte noch nie morgens: „Bitte, lass uns nicht miteinander geschlafen haben!“",
  "Ich habe noch nie mit anderen gleichen Geschlechts zusammen masturbiert.",
  "Ich habe noch nie einen Footjob gegeben / bekommen.",
  "Ich habe noch nie eine Fickbeziehung gehabt.",
  "Ich habe noch nie beim Sex an meine/n Ex gedacht.",
  "Ich habe noch nie Gleitgel benutzt.",
  "Ich habe noch nie eine Sexpuppe in Händen gehalten.",
  "Ich habe mir noch nie gewünscht, einen der Anwesenden Personen hier oral zu befriedigen.",
  "Ich habe noch nie mit jemanden Sex gehabt der viel jünger / älter war.",
  "Ich habe noch nie mit mehr als 10 verschiedenen Menschen geschlafen.",
  "Ich habe noch nie eine Geschlechtskrankheit gehabt.",
  "Ich habe noch nie eine Beziehung gehabt, in der ich mit meinem Partner keinen Sex hatte.",
  "Ich habe noch nie einen Schwangerschaftstest gemacht.",
  "Ich habe noch nie einen Vaterschaftstest gemacht.",
  "Ich hatte noch nie an mehr als 5 verschiedenen Orten Sex. (als Ort zählt z.B. „im Bett“, d.h. verschiedene Betten zählen nicht als verschiedene Orte!",
  "Ich habe noch nie mehr als 3 Orgasmen innerhalb von 24 Stunden gehabt.",
  "Ich habe mir noch nie beim Sex mit meinem Partner vorgestellt, er / sie wäre ein anderer den ich kenne.",
  "Ich habe noch nie einen Orgasmus bei einem Sextraum gehabt.",
  "Ich war noch nie in Versuchung meinen momentanen Partner zu betrügen.",
  "Ich habe noch nie einen Amateurporno gefilmt.",
  "Ich habe mich noch nie nach dem Sex geschämt",
  "Ich habe noch nie in einem Sexshop eingekauft",
  "Ich habe noch nie Sex mit jemandem gehabt, der viel älter/jünger war",
  "Ich habe noch nie einen Flotten Dreier gehabt",
  "Ich habe noch nie Verhütet",
  "Ich habe noch nie Probleme mit der Polizei bekommen",
  "Ich habe noch nie so getan, als würde ich jemanden lieben",
  "Ich habe noch nie Sex mit jemandem in diesem Raum gehabt",
  "Ich habe noch nie Oralsex an einem öffentlichen Ort gegeben oder bekomme",
  "Ich habe noch nie schmutzige Rollenspiele versucht",
  "Ich habe noch nie an Zehen gesaugt",
  "Ich habe noch nie gesehen, wie ein anderes Paar Sex hat",
  "Ich habe noch nie von jemand anderen Körperflüssigkeiten gekostet",
  "Ich habe noch nie in einem Aufzug Sex gehabt",
  "Ich habe noch nie jemand geleckt",
  "Ich habe noch nie nach einer Stunde nach dem Treffen mit jemandem geschlafen",
  "Ich habe noch nie im Auto masturbiert",
  "Ich habe noch nie Nacktfotos von mir / jemandem gemacht",
  "Ich habe noch nie mit jemandem geschlafen, der mehr als doppelt so alt war wie ich",
  "Ich habe noch nie für mehr als eine halbe Stunde mit meinem Freund / meiner Freundin Sex gehabt",
  "Ich habe noch nie die Schamhaare meines Partners rasiert",
  "Ich habe noch nie einen Prominenten geküsst",
  "Ich habe noch nie Muttermilch geschmeckt",
  "Ich habe noch nie einen Daumen in mich hineingesteckt",
  "Ich habe noch nie masturbiert, ohne mich hinzulegen",
  "Ich habe noch nie einen Orgasmus vorgetäuscht",
  "Ich habe noch nie einen feuchten Traum gehabt",
  "Ich habe noch nie meine Brüste in der Öffentlichkeit gezeigt",
  "Ich habe noch nie mit mehr als fünf Personen in einem Bett geschlafen",
  "Ich habe noch nie unter der Dusche masturbiert",
  "Ich habe noch nie in einem Zimmer eines anderen Familienmitglieds Sex gehabt",
  "Ich habe noch nie mit mehr als einer Person innerhalb von 24 Stunden geschlafen",
  "Ich habe noch nie vor anderen Leuten masturbiert",
  "Ich habe noch nie mit jemandem geschlafen, von dem ich dachte, er sei hässlich",
  "Ich habe noch nie meinen Partner beim Sex geschlagen",
  "Ich habe noch nie beim ersten Date geküsst",
  "Ich habe noch nie einen Korb bekommen",
  "Ich habe noch nie mit jemanden geschlafen, den ich online getroffen habe",
  "Ich habe noch nie so gelacht, dass ich in meine Hose gepinkelt habe",
  "Ich habe noch nie länger als eine Woche nicht geduscht",
  "Ich habe noch nie versucht, in der Öffentlichkeit eine Ehefrau diskret auszuwählen",
  "Ich habe noch nie ein Familienmitglied im Badezimmer nackt gesehen",
  "Ich habe noch nie mich übergeben, weil jemand anderes sich übergeben hat",
  "Ich habe noch nie mehr als 10 Geschlechtspartner gehabt",
];

function Game(props) {
  useEvent("mouseup", newQuestion);
  useEvent("touchend", newQuestion);

  const [players, setPlayers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [player, setPlayer] = useState();
  const [question, setQuestion] = useState("Click to start");

  const getPlayers = () => {
    const response = props.getPlayers();
    setPlayers(response);
    setLoading(false);
  };

  useEffect(() => {
    getPlayers();
  }, []);

  function newQuestion() {
    /*  const randomQuestionNumber = Math.floor(Math.random() * 2);
    if (randomQuestionNumber === 0) {
      setQuestion(questions[randomQuestionNumber]);
      const randomPlayerNumber = Math.floor(Math.random() * players.length);
      setPlayer(players[randomPlayerNumber] + ": ");
    } else {
      const randomQuestionNumber = Math.floor(
        Math.random() * ichhabenochnie.length
      );
      setQuestion(ichhabenochnie[randomQuestionNumber]);
      setPlayer("");
    } */
    const randomQuestionNumber = Math.floor(
      Math.random() * ichhabenochnie.length
    );
    setQuestion(ichhabenochnie[randomQuestionNumber]);
    setPlayer("");
  }

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <div>
        <p>
          {player} {question}
        </p>
        <Link to="/">
          <button className="btn-endgame">End Game</button>
        </Link>
      </div>
    );
  }
}

export default Game;
