import { useRef } from "react";
import "./Start.css";
import { useHistory } from "react-router-dom";

function Start(props) {
  const history = useHistory();
  const nameInputRef = useRef();
  const formRef = useRef();

  function addPlayer(ev) {
    ev.preventDefault();
    if (nameInputRef.current.value !== "") {
      props.setData(nameInputRef.current.value);
      formRef.current.reset();
    }
  }

  function resetPlayers() {
    props.clearData();
  }

  function startGame() {
    history.push("/game");
  }

  return (
    <div className="container">
      <h1>Iburg Inside Drinking Game</h1>
      <div>
        <form onSubmit={addPlayer} ref={formRef}>
          <input
            aria-label="Add Player"
            autoComplete="off"
            autoFocus="on"
            type="text"
            name="name"
            ref={nameInputRef}
          ></input>
        </form>
        <div className="button-container">
          <button onClick={addPlayer}>Add Player</button>
          <button onClick={startGame}>Start Game</button>
        </div>

        <button className="btn-endgame" onClick={resetPlayers}>
          Reset Players
        </button>
      </div>
    </div>
  );
}

export default Start;
