import Start from "./Start";
import Game from "./Game";
import { Route, Switch } from "react-router";

function App() {
  let players = [];

  function setPlayers(data) {
    players.push(data);
  }
  function resetPlayers() {
    players = [];
  }

  function getPlayers() {
    return players;
  }

  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Start setData={setPlayers} clearData={resetPlayers} />
        </Route>
        <Route path="/game">
          <Game players={players} getPlayers={getPlayers} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
